
import "@/assets/styles.css";
import { Vue, Component } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";
import {
    ResponseDataChart as rspChr,
    ChartError,
    monaguillo
} from "@/interfaces";
import * as types from "@/store/types";
import { sleep } from "@/utils";
import { TOKEN } from "@/services/auth/auth-service";
import { Eoption, noData } from "@/utils";
@Component({
    components: {
        FilterForm: () => import("@/pages/components/forms/FilterFormV2.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue"),
        Echart: () => import("@/components/charts/eChart.vue")
    },
    methods: {
        ...mapMutations({
            setFilterLoading: types.MUTATE_APP_FILTER_LOADING,
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        }),
        getColor(Nombre) {
            if (Nombre == "Total") return "light-grey";
            else return "white";
        }
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class Monaguillo extends Vue {
    setFilterLoading!: (state: boolean) => void;
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    userIdentifier?: number;
    default: monaguillo = {
        id: 0,
        install: "",
        elements: [],
        element: "Nueva Consulta",
        start: "",
        startDate: "",
        startTime: "",
        end: "",
        endDate: "",
        endTime: "",
        first: 0,
        last: 0,
        difference: 0,
        csv: "",
        heatmap: noData
    };
    monaguillos: monaguillo[] = [
        {
            id: 0,
            install: "",
            elements: [],
            element: "Nueva Consulta",
            start: "",
            startDate: "",
            startTime: "",
            end: "",
            endDate: "",
            endTime: "",
            first: 0,
            last: 0,
            difference: 0,
            csv: "",
            heatmap: noData
        }
    ];
    aux = 0;
    tab: any = null;
    isAdmin = false;
    companies: string[] = [];
    company = "";
    clients: string[] = [];
    cliente: number | string | null | undefined = null;
    installs: string[] = [];
    install = "";
    elements: string[] = [];
    element = "";
    paramElements: any[] = [];
    paramsServices: any[] = [];
    paramInversors: any[] = [];
    services: object = {};
    listServices: any[] = [];
    listInversors: any[] = [];
    alturaCW = 500;
    alturaPie = 400;
    initEchart = Eoption;
    showFilter = true;
    showAll = false;
    start = "";
    startDate = "";
    startTime = "";
    end = "";
    endDate = "";
    endTime = "";
    first = 0;
    last = 0;
    diferencia = 0;
    window = "15minutos";
    CSV = "";
    heatmap1: any = noData;
    errors: ChartError[] = [];

    /**
     * Methods
     */
    mounted() {
        this.isAdmin = Vue.prototype.$user.get().role == "admin" ? true : false;
        this.cliente = this.isAdmin ? null : this.userIdentifier;
        if (this.isAdmin) {
            this.fetchCompany();
        } else {
            this.localStorage();
        }
    }

    async fetchCompany() {
        this.setFilterLoading(true);
        try {
            const companyResponse = await this.$api.SQLcompany<any>();
            if (companyResponse.ok) {
                this.companies = companyResponse.data;
                this.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchClient() {
        this.setFilterLoading(true);
        try {
            const clientResponse = await this.$api.SQLclient<any>(
                this.company.split(" - ")[0]
            );
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.cliente = this.clients[0];
                this.localStorage();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async localStorage() {
        try {
            // throw Error;
            const Ncliente = this.isAdmin
                ? typeof this.cliente === "string"
                    ? Number(this.cliente.split(" - ")[0])
                    : null
                : Number(this.cliente);
            this.paramElements = [];
            const storageInstallationsResponse = await this.$api.SQLstorageInstallations<
                any
            >(Ncliente);
            if (storageInstallationsResponse.ok) {
                const elementosPuerto = storageInstallationsResponse.data;
                elementosPuerto.forEach(element => {
                    const inst = `${element.Ninstala} - ${element.Tinstala}`;
                    if (!this.installs.some(install => install == inst)) {
                        this.installs.push(inst);
                    }
                    let name =
                        element.Npadre == 0
                            ? element.Ninstala != 352
                                ? `${element.Nele} - ${element.Tlugar}/${element.Tele}`
                                : `${element.Nele} - ${element.Tele}`
                            : `${element.Npadre}/${element.Nele} - ${element.Tele}`;
                    let box = element.Tserie;
                    let analyzer: string | null = null;
                    if (element.Nestanda != 0 && element.Nestanda != 4) {
                        if (
                            element.Tip != "192.168.250.250" &&
                            element.Ninstala != 292 &&
                            element.Ninstala != 304
                        ) {
                            analyzer = element.Tip;
                        }
                    }

                    const jerar = 0;
                    const nPadre: string | null = null;
                    const NpaCons = element.Npadre;
                    const Ntipo = element.Ntipo;
                    let Tdir = element.dir;
                    switch (element.Nservicio) {
                        case 1:
                            if (element.Nfam != 12) {
                                this.paramElements.push({
                                    install: inst,
                                    box: box,
                                    analyzer: analyzer,
                                    Telement: name,
                                    Tdir: Ntipo != 6 ? Tdir : element.Tele,
                                    Jerar: jerar,
                                    NpaCons: NpaCons,
                                    nPadre: nPadre,
                                    ntipo: Ntipo
                                });
                            } else {
                                this.paramInversors.push({
                                    install: inst,
                                    box: box,
                                    analyzer: analyzer,
                                    Telement: name,
                                    Tdir: Ntipo != 6 ? Tdir : element.Tele,
                                    Jerar: jerar,
                                    NpaCons: NpaCons,
                                    nPadre: nPadre,
                                    ntipo: Ntipo
                                });
                            }

                            break;
                        case 2:
                            this.paramsServices.push({
                                install: inst,
                                box: box,
                                analyzer: analyzer,
                                Telement: name,
                                Tdir: Ntipo != 6 ? Tdir : element.Tele,
                                Jerar: jerar,
                                NpaCons: NpaCons,
                                nPadre: nPadre,
                                service: "Gas",
                                Nestanda: element.Nestanda
                            });
                            break;
                        case 3:
                            this.paramsServices.push({
                                install: inst,
                                box: box,
                                analyzer: analyzer,
                                Telement: name,
                                Tdir: Ntipo != 6 ? Tdir : element.Tele,
                                Jerar: jerar,
                                NpaCons: NpaCons,
                                nPadre: nPadre,
                                service: "Agua",
                                Nestanda: element.Nestanda
                            });
                            break;
                        case 4:
                            if (element.Ninstala == 304) {
                                name = "549 - CGBT/GenN2";
                                box = "1907_0449_24";
                                Tdir = "F7";
                                analyzer = "3";
                            } else if (element.Ninstala == 292) {
                                name = "280 - CGBT/GenN2";
                                box = "1907_0458_24";
                                Tdir = "F7";
                                analyzer = "3";
                            }
                            this.paramsServices.push({
                                install: inst,
                                box: box,
                                analyzer: analyzer,
                                Telement: name,
                                Tdir: Ntipo != 6 ? Tdir : element.Tele,
                                Jerar: jerar,
                                NpaCons: NpaCons,
                                nPadre: nPadre,
                                service: "Nitrogeno",
                                Nestanda: element.Nestanda
                            });
                            break;
                        case 5:
                            this.paramsServices.push({
                                install: inst,
                                box: box,
                                analyzer: analyzer,
                                Telement: name,
                                Tdir: Ntipo != 6 ? Tdir : element.Tele,
                                Jerar: jerar,
                                NpaCons: NpaCons,
                                nPadre: nPadre,
                                service: "Calorifico",
                                Nestanda: element.Nestanda
                            });
                            break;
                        default:
                            console.info("Alguien metío mal el dato, pregunte");
                    }
                });
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    async fetchData(lment) {
        this.setProgressBar(true);
        lment.heatmap1 = noData;
        lment.csv = "";

        if (
            lment.startDate &&
            lment.startTime &&
            lment.endDate &&
            lment.endTime
        ) {
            lment.end = `${lment.endDate}T${lment.endTime}:00Z`;
            lment.start = `${lment.startDate}T${lment.startTime}:00Z`;
            if (new Date(lment.end) > new Date(lment.start)) {
                try {
                    const monaguilloReq = await this.$api.consumoPeriodo<
                        rspChr
                    >(
                        lment.start,
                        lment.end,
                        this.paramElements.filter(
                            element => element.Telement == lment.element
                        )
                    );
                    if (monaguilloReq.ok) {
                        lment.heatmap = monaguilloReq.data["heatmap"];
                        lment.csv = monaguilloReq.data["csv"];
                        lment.first = monaguilloReq.data["first"];
                        lment.last = monaguilloReq.data["last"];
                        lment.difference = monaguilloReq.data["difference"];
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        this.showError(error.message);
                    }
                }
            } else {
                {
                    this.showError(
                        "La fecha inicial no debe ser más nueva que la fecha final"
                    );
                }
            }
        } else {
            this.showError("No ha seleccionado fechas completas");
        }

        this.setProgressBar(false);
    }

    listElementos(lment) {
        lment.elements = [];
        for (const i of this.paramElements) {
            if (i.install == lment.install) {
                lment.elements.push(i.Telement);
            }
        }
    }

    onFilterUpdated(lment) {
        if (Vue.$cookies.isKey(TOKEN)) {
            //logica de los periodType
            this.fetchData(lment);
        } else {
            this.$router.push("/signin");
        }
    }
    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    resetCSV() {
        this.CSV = "";
    }
    async waited() {
        this.aux = this.tab;
        await sleep(300);
        this.tab = this.aux;
    }
    downloadCSV(lment) {
        // window.location.href =
        //     window.location.origin +
        //     `/api/common/download-csv?box=irizar_e01&element=P1&start=2022-04-05&end=2022-04-06`;

        const anchor = document.createElement("a");
        anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(lment.csv);
        anchor.target = "_blank";
        anchor.download =
            lment.element.split(" - ")[1] +
            "_Consumos_" +
            lment.start +
            "_" +
            lment.end +
            ".csv".replace(":", "-");
        anchor.click();
    }

    // formatDates() {
    //     const options = {
    //         weekday: "short",
    //         year: "numeric",
    //         month: "short",
    //         day: "numeric"
    //     };
    //     const timezone = String(this.$t("timeZone"));
    //     if (this.filterForm.comparative) {
    //         if (this.filterForm?.dates[0] && this.filterForm?.dates[1])
    //             return (
    //                 new Intl.DateTimeFormat(timezone, options).format(
    //                     new Date(this.start2)
    //                 ) +
    //                 " - " +
    //                 new Intl.DateTimeFormat(timezone, options).format(
    //                     new Date(this.end2)
    //                 )
    //             );
    //     } else {
    //         if (this.filterForm?.dates[0] && this.filterForm?.dates[1])
    //             return (
    //                 new Intl.DateTimeFormat(timezone, options).format(
    //                     new Date(this.filterForm?.dates[0])
    //                 ) +
    //                 " - " +
    //                 new Intl.DateTimeFormat(timezone, options).format(
    //                     new Date(this.filterForm.dates[1])
    //                 )
    //             );
    //     }
    //     return "";
    // }

    resetForm() {
        this.paramElements = [];
        this.paramsServices = [];
        this.paramInversors = [];
    }

    formatDatesInit<String>(lment) {
        if (lment.startDate && lment.startTime) {
            return `${lment.startDate} ${lment.startTime}:00`;
        }
        return "";
    }

    formatDatesEnd(lment) {
        if (lment.endDate && lment.endTime) {
            return `${lment.endDate} ${lment.endTime}:00`;
        }
        return "";
    }

    get today() {
        return new Date().toISOString().split("T")[0];
    }

    get allowHours() {
        return x => x >= 0 && x <= 24;
        // if (new Date(this.startDate) < new Date()) {
        //     return x => x >= 0 && x <= 24;
        // } else {
        //     return x => x < new Date().getHours() - 2;
        // }
    }

    get allowMinutes() {
        return x => x % 15 === 0;
        // if (new Date(this.startDate) < new Date()) {
        //     return x => x >= 0 && x <= 60;
        // } else {
        //     return x => x < new Date().getMinutes() && x % 15 === 0;
        // }
    }

    addMonaguillos() {
        const aux = JSON.parse(JSON.stringify(this.default));
        aux.id = this.monaguillos[this.monaguillos.length - 1].id + 1;
        this.monaguillos.push(aux);
        this.tab = this.monaguillos.length - 1;
    }

    removeMonaguillos(lment) {
        if (this.monaguillos.length > 1) {
            const aux = this.monaguillos.findIndex(x => x.id == lment.id);
            this.monaguillos.splice(aux, 1);
        } else {
            this.showError("No se puede eliminar elemento");
        }
    }

    getEchart(option) {
        if (option == 0) {
            return 0;
        }
        if (option.dataset) {
            if (option.dataset.source) {
                if (option.dataset.source.length > 0) {
                    return { ...Eoption, ...option };
                }
            }
        }
        return noData;
    }
}
